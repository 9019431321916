import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";
import "./Payment.css";
import Styles from "./Styles";
import { Form, Field } from "react-final-form";
import Card from "./Card";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./cardUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentScreen = ({ history }) => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const onSubmit = async (values) => {
    await sleep(300);
    // window.alert(JSON.stringify(values, 0, 2))
  };
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  if (!shippingAddress.address) {
    history.push("/shipping");
  }

  const [paymentMethod, setPaymentMethod] = useState("Custom Payment");

  const dispatch = useDispatch();
  const validateCardNumber = (number) => {
    //Check if the number contains only numeric value
    //and is of between 13 to 19 digits
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(number)) {
      return false;
    }

    return luhnCheck(number);
  };

  const luhnCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    //Check if it is divisible by 10 or not.
    return checksum % 10 == 0;
  };

  const submitHandler = (e) => {
    // setPaymentMethod(e);
    let card_holder_name = e.name;
    let card_number = e.number;
    let card_expiry_date = e.expiry;
    let card_date_split = card_expiry_date.split("/");
    // let card_date = card_date_split[0];
    // let card_month = card_date_split[1];
    let card_cvc = e.cvc;
    card_number = card_number.replace(/\s/g, "");
    const res = validateCardNumber(card_number);
    if (card_holder_name == "") {
      toast("Invalid Name");
    } else if (card_holder_name.length < 3) {
      toast.error("Invalid Name");
    } else if (parseInt(card_holder_name) || parseFloat(card_holder_name)) {
      toast.error("Invalid Name");
    } else if (!res) {
      toast.error("Invalid Card Number");
    } else if (card_cvc.length < 2) {
      toast.error("Invalid CVC");
    }
    else{
      dispatch(savePaymentMethod({card_holder_name, card_number, card_expiry_date, card_cvc}));
      if(card_holder_name, card_number, card_expiry_date, card_cvc){
        history.push("/placeorder");
      }
    }
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <Styles>
        <Form
          onSubmit={submitHandler}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            active,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  number={values.number || ""}
                  name={values.name || ""}
                  expiry={values.expiry || ""}
                  cvc={values.cvc || ""}
                  focused={active}
                />
                <div>
                  <div
                    className="card space icon-relative"
                    style={{ width: "100%" }}
                  >
                    <label className="label">Card holder:</label>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Card Holder Name"
                      id="card_number"
                      className="input"
                      required
                    />
                    <i className="fas fa-user" style={{ color: "#AFAFAF" }}></i>
                  </div>
                  <span id="name_error" className="form_error"></span>
                </div>
                <div>
                  <div
                    className="card space icon-relative"
                    style={{ width: "100%" }}
                  >
                    <label className="label">Card number:</label>
                    <Field
                      name="number"
                      component="input"
                      type="text"
                      pattern="[\d| ]{16,22}"
                      placeholder="Card Number"
                      format={formatCreditCardNumber}
                      id="card_number"
                      className="input"
                      data-mask="0000 0000 0000 0000"
                      required
                    />
                    <i
                      className="far fa-credit-card"
                      style={{ color: "#AFAFAF" }}
                    ></i>
                  </div>
                  <span id="card_error" className="form_error"></span>
                </div>
                <div>
                  <div className="card-grp space">
                    <div className="card-item icon-relative">
                      <label className="label">Expiry date:</label>
                      <Field
                        name="expiry"
                        component="input"
                        type="text"
                        pattern="\d\d/\d\d"
                        format={formatExpirationDate}
                        id="card_expiry_date"
                        className="input"
                        data-mask="00 / 00"
                        placeholder="mm / yy"
                        required
                      />
                      <i
                        className="far fa-calendar-alt"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                    <div className="card-item icon-relative">
                      <label className="label">CVC:</label>
                      <Field
                        name="cvc"
                        component="input"
                        type="text"
                        pattern="\d{3,4}"
                        placeholder="CVC"
                        format={formatCVC}
                        id="card_cvc"
                        className="input"
                        data-mask="000"
                        required
                      />
                      <i
                        className="fas fa-lock"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                    <div
                      className="card-item icon-relative"
                      style={{ display: "none" }}
                    >
                      <label className="label">CVC:</label>
                      <input
                        id="order_id"
                        type="text"
                        className="input"
                        name="order_id"
                        placeholder="CVC"
                      />
                      <i
                        className="fas fa-lock"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                    <div
                      className="card-item icon-relative"
                      style={{ display: "none" }}
                    >
                      <label className="label">CVC:</label>
                      <input
                        id="order_price"
                        type="text"
                        className="input"
                        name="order_price"
                        placeholder="CVC"
                      />
                      <i
                        className="fas fa-lock"
                        style={{ color: "#AFAFAF" }}
                      ></i>
                    </div>
                  </div>

                  <span id="exp_date_error" className="form_error"></span>
                  <br />
                  <span id="cvc_error" className="form_error"></span>
                  {/* <div className="card space icon-relative">
                    <label className="label">Card number:</label>
                    <Field
                      name="expiry"
                      component="input"
                      type="text"
                      pattern="\d\d/\d\d"
                      format={formatExpirationDate}
                      id="card_expiry_date"
                      className="input"
                      data-mask="00 / 00"
                      placeholder="mm / yy"
                      required
                    />
                    <i
                      className="far fa-credit-card"
                      style={{ color: "#AFAFAF" }}
                    ></i>
                  </div> */}
                </div>
                <div className="buttons">
                  <button type="submit" disabled={submitting}>
                    Pay Now
                  </button>
                  <button
                    type="button"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </button>
                </div>
              </form>
            );
          }}
        />
      </Styles>
      <ToastContainer />
    </FormContainer>
  );
};

export default PaymentScreen;
